var Toast = function(options) {
  // options
  var opts = options || {};

  // defaults
  opts.defaultStyle = opts.defaultStyle || "success";
  opts.displayTime = opts.displayTime || 0;

  // show function
  var showFn = function(next) {
    jQuery(this).css({
      opacity: 1,
      "margin-top": 15
    });
    next();
  };

  // hide function
  var hideFn = function(next) {
    var width = jQuery(this).outerWidth() + 20;
    jQuery(this).css({
      "margin-right": "-" + width + "px",
      opacity: 0
    });

    next();
  };

  // build toast container
  var container = jQuery('<div class="toast-container"></div>');
  container.prependTo(jQuery("body"));

  return function(txt, cls, time) {
    cls = cls || opts.defaultStyle;
    time = typeof time == "undefined" ? opts.displayTime : time;

    // prepare html
    var timer = "";
    if (time > 0) {
      timer = '<p class="timer"></p>';
    }

    var elem = jQuery(
      '<div class="toast ' +
        cls +
        '">' +
        '   <p class="msg">' +
        txt +
        "</p>" +
        "   <button>&times;</button>" +
        timer +
        "</div>"
    );

    // click to remove
    elem.find("button").click(function() {
      jQuery(this)
        .closest(".toast")
        .remove();
    });

    // add to DOM
    elem
      .prependTo(container)
      .css("margin-top", "-" + elem.outerHeight() + "px")
      .queue(showFn);

    if (time == 0) return elem;

    // animate timer
    var percent = 100;
    var interval = 10;
    var part = (interval * 100) / time;
    var timerId = setInterval(function() {
      percent -= part;
      elem.find(".timer").css("width", percent + "%");

      if (percent <= 0) {
        clearInterval(timerId);
      }
    }, interval);

    // delayed hide
    elem
      .delay(time)
      .queue(hideFn)
      .delay(600)
      .queue(function(next) {
        jQuery(this).remove();
        next();
      });

    return elem;
  };
};

// init mainToast
jQuery(document).ready(function() {
  window.mainToast = new Toast({
    displayTime: 5000
  });
});
