/*
---
name: Locale.pl-PL.Chosen
description: Polish Language File for Chosen
authors: Jonnathan Soares
requires: [More/Locale]
provides: Locale.pl-PL.Chosen
...
*/
Locale.define('pl-PL', 'Chosen', {
    placeholder: function(multiple) {
        return (multiple ? 'Wybierz opcje' : 'Wybierz opcję');
    },
    noResults: 'Brak wyników zawierających'
});