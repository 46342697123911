/*
---
name: Chosen
description: Creates a Picker, which can be used for anything
authors: Patrick Filler, Jules Janssen, Jonnathan Soares
requires: [Core/*, More/Locale]
provides: Chosen
...
*/
Elements.implement({
    chosen: function(a) {
        return this.each(function(b) {
            if (!b.hasClass("chzn-done")) return new Chosen(b, a)
        })
    }
});
var Chosen = new Class({
    Implements: Options,
    Binds: "test_active_click,container_mousedown,container_mouseup,mouse_enter,mouse_leave,search_results_mouseup,search_results_mouseover,search_results_mouseout,input_blur,keyup_checker,keydown_checker,choices_click,input_focus,activate_field,results_update_field".split(","),
    options: {
        allow_single_deselect: !1,
        disable_search_threshold: 0
    },
    active_field: !1,
    mouse_on_container: !1,
    results_showing: !1,
    result_highlighted: null,
    result_single_selected: null,
    choices: 0,
    initialize: function(a,
        b) {
        this.setOptions(b);
        this.form_field = a;
        this.is_multiple = this.form_field.multiple;
        this.is_rtl = this.form_field.hasClass("chzn-rtl");
        this.set_up_html();
        this.register_observers();
        this.form_field.addClass("chzn-done")
    },
    set_up_html: function() {
        this.form_field.get("id") || this.form_field.set("id", String.uniqueID());
        this.container_id = this.form_field.id.replace(/(:|\.)/g, "_") + "_chzn";
        this.f_width = this.form_field.measure(function() {
            return this.getSize().x
        });
        this.default_text = this.form_field.get("data-placeholder") ?
            this.form_field.get("data-placeholder") : Locale.get("Chosen.placeholder", this.form_field.multiple);
        this.container = new Element("div", {
            id: this.container_id,
            "class": "chzn-container" + (this.is_rtl ? " chzn-rtl" : "") + " chzn-container-" + (this.is_multiple ? "multi" : "single"),
            styles: {
                width: this.f_width
            }
        });
        this.is_multiple ? this.container.set("html", '<ul class="chzn-choices"><li class="search-field"><input type="text" value="' + this.default_text + '" class="default" autocomplete="off" style="width:100%;" /></li></ul><div class="chzn-drop" style="left:-9000px;"><ul class="chzn-results"></ul></div>') :
            this.container.set("html", '<a href="javascript:void(0)" class="chzn-single"><span>' + this.default_text + '</span><div><b></b></div></a><div class="chzn-drop" style="left:-9000px;"><div class="chzn-search"><input type="text" autocomplete="off" /></div><ul class="chzn-results"></ul></div>');
        this.form_field.setStyle("display", "none").grab(this.container, "after");
        this.dropdown = this.container.getElement("div.chzn-drop");
        var a = this.container.getCoordinates().height,
            b = this.f_width - this.dropdown.get_side_border_padding();
        this.dropdown.setStyles({
            width: b,
            top: a
        });
        this.search_field = this.container.getElement("input");
        this.search_results = this.container.getElement("ul.chzn-results");
        this.search_field_scale();
        this.search_no_results = this.container.getElement("li.no-results");
        this.is_multiple ? (this.search_choices = this.container.getElement("ul.chzn-choices"), this.search_container = this.container.getElement("li.search-field")) : (this.search_container = this.container.getElement("div.chzn-search"), this.selected_item = this.container.getElement(".chzn-single"),
            this.search_field.setStyle("width", b - this.search_container.get_side_border_padding() - this.search_field.get_side_border_padding()));
        this.results_build();
        this.set_tab_index();
        this.form_field.fireEvent("liszt:ready", this)
    },
    register_observers: function() {
        this.container.addEvents({
            mousedown: this.container_mousedown,
            mouseup: this.container_mouseup,
            mouseenter: this.mouse_enter,
            mouseleave: this.mouse_leave
        });
        this.search_results.addEvents({
            mouseup: this.search_results_mouseup,
            mouseover: this.search_results_mouseover,
            mouseout: this.search_results_mouseout
        });
        this.form_field.addEvent("liszt:updated", this.results_update_field);

        this.search_field.addEvents({
            blur: this.input_blur,
            keyup: this.keyup_checker,
            keydown: this.keydown_checker
        });
        this.is_multiple ? (this.search_choices.addEvent("click", this.choices_click), this.search_field.addEvent("focus", this.input_focus)) : this.selected_item.addEvent("focus", this.activate_field)
    },
    unregister_observers: function() {
        this.container.removeEvents({
            mousedown: this.container_mousedown,
            mouseup: this.container_mouseup,
            mouseenter: this.mouse_enter,
            mouseleave: this.mouse_leave
        });
        this.search_results.removeEvents({
            mouseup: this.search_results_mouseup,
            mouseover: this.search_results_mouseover,
            mouseout: this.search_results_mouseout
        });
        this.form_field.removeEvent("liszt:updated", this.results_update_field);
        this.search_field.removeEvents({
            blur: this.input_blur,
            keyup: this.keyup_checker,
            keydown: this.keydown_checker
        });
        this.is_multiple ? (this.search_choices.removeEvent("click", this.choices_click), this.search_field.removeEvent("focus",
            this.input_focus)) : this.selected_item.removeEvent("focus", this.activate_field);
        document.removeEvent("click", this.test_active_click)
    },
    search_field_disabled: function() {
        (this.is_disabled = this.form_field.get("disabled")) ? (this.container.addClass("chzn-disabled"), this.search_field.set("disabled", !0), this.is_multiple || this.selected_item.removeEvent("focus", this.activate_field), this.close_field()) : (this.container.removeClass("chzn-disabled"), this.search_field.set("disabled", !1), this.is_multiple || this.selected_item.addEvent("focus",
            this.activate_field))
    },
    container_mousedown: function(a) {
        if (!this.is_disabled) {
            var b = null != a ? a.target.hasClass("search-choice-close") : !1;
            a && "mousedown" === a.type && a.stopPropagation();
            if (!this.pending_destroy_click && !b) {
                if (this.active_field) {
                    if (!this.is_multiple && a && (a.target === this.selected_item || a.target.getParents("a.chzn-single").length)) a.preventDefault(), this.results_toggle()
                } else this.is_multiple && this.search_field.set("value", ""), document.addEvent("click", this.test_active_click), this.results_show();
                this.activate_field()
            } else this.pending_destroy_click = !1
        }
    },
    container_mouseup: function(a) {
        if ("ABBR" === a.target.get("tag").toUpperCase()) return this.results_reset(a)
    },
    mouse_enter: function() {
        this.mouse_on_container = !0
    },
    mouse_leave: function() {
        this.mouse_on_container = !1
    },
    input_focus: function() {
        this.active_field || setTimeout(this.container_mousedown, 50)
    },
    input_blur: function() {
        if (!this.mouse_on_container) this.active_field = !1, setTimeout(this.blur_test.bind(this), 100)
    },
    blur_test: function() {
        !this.active_field &&
            this.container.hasClass("chzn-container-active") && this.close_field()
    },
    close_field: function() {
        document.removeEvent("click", this.test_active_click);
        this.is_multiple || (this.selected_item.set("tabindex", this.search_field.get("tabindex")), this.search_field.set("tabindex", -1));
        this.active_field = !1;
        this.results_hide();
        this.container.removeClass("chzn-container-active");
        this.winnow_results_clear();
        this.clear_backstroke();
        this.show_search_field_default();
        this.search_field_scale()
    },
    activate_field: function() {
        !this.is_multiple &&
            !this.active_field && (this.search_field.set("tabindex", this.selected_item.get("tabindex")), this.selected_item.set("tabindex", -1));
        this.container.addClass("chzn-container-active");
        this.active_field = !0;
        this.search_field.set("value", this.search_field.get("value"));
        this.search_field.focus()
    },
    test_active_click: function(a) {

        if (a.target.get("tag") == 'input') // disable click event on chips
            a.target.getParents("#" + this.container_id).length ? this.active_field =! 0 : this.close_field();
    },
    results_build: function() {
        this.parsing = !0;
        this.results_data = this.form_field.select_to_array();
        this.is_multiple && 0 < this.choices ? (this.search_choices.getElements("li.search-choice").destroy(), this.choices = 0) : this.is_multiple || (this.selected_item.getElements("span").set("text", this.default_text), this.form_field.options.length <= this.options.disable_search_threshold ? this.container.addClass("chzn-container-single-nosearch") : this.container.removeClass("chzn-container-single-nosearch"));
        var a = "";
        this.results_data.each(function(b) {
            b.group ? a += this.result_add_group(b) : b.empty || (a += this.result_add_option(b),
                b.selected && this.is_multiple ? this.choice_build(b) : b.selected && !this.is_multiple && (this.selected_item.getElements("span").set("text", b.text), this.options.allow_single_deselect && this.single_deselect_control_build()))
        }, this);
        this.search_field_disabled();
        this.show_search_field_default();
        this.search_field_scale();
        this.search_results.set("html", a);
        this.parsing = !1
    },
    result_add_group: function(a) {
        if (a.disabled) return "";
        a.dom_id = this.container_id + "_g_" + a.array_index;
        return '<li id="' + a.dom_id + '" class="group-result"><div>' +
            a.label + "</div></li>"
    },
    result_add_option: function(a) {
        if (a.disabled) return "";
        a.dom_id = this.container_id + "_o_" + a.array_index;
        var b = a.selected && this.is_multiple ? [] : ["active-result"];
        a.selected && b.push("result-selected");
        null != a.group_array_index && b.push("group-option");
        "" !== a.classes && b.push(a.classes);
        var d = "" !== a.style.cssText ? ' style="' + a.style + '"' : "";
        return '<li id="' + a.dom_id + '" class="' + b.join(" ") + '"' + d + ">" + a.html + "</li>"
    },
    results_update_field: function() {
        this.result_clear_highlight();
        this.result_single_selected =
            null;
        this.results_build()
    },
    result_do_highlight: function(a) {
        if (a) {
            this.result_clear_highlight();
            this.result_highlight = a;
            this.result_highlight.addClass("highlighted");
            var a = parseInt(this.search_results.getStyle("maxHeight"), 10),
                b = this.search_results.getScroll().y,
                d = a + b,
                e = this.result_highlight.getPosition(this.search_results).y + this.search_results.getScroll().y,
                c = e + this.result_highlight.getCoordinates().height;
            c >= d ? this.search_results.scrollTo(0, 0 < c - a ? c - a : 0) : e < b && this.search_results.scrollTo(0, e)
        }
    },
    result_clear_highlight: function() {
        this.result_highlight && this.result_highlight.removeClass("highlighted");
        this.result_highlight = null
    },
    results_toggle: function() {
        this.results_showing ? this.results_hide() : this.results_show()
    },
    results_show: function() {
        this.is_multiple || (this.selected_item.addClass("chzn-single-with-drop"), this.result_single_selected && this.result_do_highlight(this.result_single_selected));
        this.dropdown.setStyles({
            top: this.is_multiple ? this.container.getElement("li.search-field").getCoordinates().height - 10 : this.container.getCoordinates().height -
                1,
            left: 0
        });
        this.results_showing = !0;
        this.search_field.focus();
        this.search_field.set("value", this.search_field.get("value"));
        this.winnow_results()
    },
    results_hide: function() {
        this.is_multiple || this.selected_item.removeClass("chzn-single-with-drop");
        this.result_clear_highlight();
        this.dropdown.setStyle("left", -9E3);
        this.results_showing = !1
    },
    set_tab_index: function() {
        if (this.form_field.get("tabindex")) {
            var a = this.form_field.get("tabindex");
            this.form_field.set("tabindex", -1);
            this.is_multiple ? this.search_field.set("tabindex",
                a) : (this.selected_item.set("tabindex", a), this.search_field.set("tabindex", -1))
        }
    },
    show_search_field_default: function() {
        this.is_multiple && 1 > this.choices && !this.active_field ? (this.search_field.set("value", this.default_text), this.search_field.addClass("default")) : (this.search_field.set("value", ""), this.search_field.removeClass("default"))
    },
    search_results_mouseup: function(a) {
        var b = a.target.hasClass("active-result") ? a.target : a.target.getParent(".active-result");
        if (b) this.result_highlight = b, this.result_select(a)
    },
    search_results_mouseover: function(a) {
        (a = a.target.hasClass("active-result") ? a.target : a.target.getParent(".active-result")) && this.result_do_highlight(a)
    },
    search_results_mouseout: function(a) {
        (a.target.hasClass("active-result") || a.target.getParent(".active-result")) && this.result_clear_highlight()
    },
    choices_click: function(a) {
        a.preventDefault();
        this.active_field && !a.target.hasClass("search-choice") && !a.target.getParent(".search-choice") && !this.results_showing && this.results_show()
    },
    choice_build: function(a) {
        var b =
            this.container_id + "_c_" + a.array_index;
        this.choices += 1;
        a = (new Element("li", {
            id: b
        })).addClass("search-choice").set("html", "<span>" + a.html + '</span><a href="#" class="search-choice-close" rel="' + a.array_index + '"></a>');
        this.search_container.grab(a, "after");
        a.getElement("a").addEvent("click", this.choice_destroy_link_click.bind(this))
    },
    choice_destroy_link_click: function(a) {
        a.preventDefault();
        this.is_disabled ? a.stop() : (this.pending_destroy_click = !0, this.choice_destroy(a.target))
    },
    choice_destroy: function(a) {
        this.choices -=
            1;
        this.show_search_field_default();
        this.is_multiple && 0 < this.choices && 1 > this.search_field.value.length && this.results_hide();
        this.result_deselect(a.get("rel"));
        a.getParent("li").destroy()
    },
    results_reset: function(a) {
        this.form_field.options[0].selected = !0;
        this.selected_item.getElement("span").set("text", this.default_text);
        this.show_search_field_default();
        a.target.destroy();
        this.form_field.fireEvent("change");
        this.active_field && this.results_hide()
    },
    result_select: function(a) {
        if (this.result_highlight) {
            var b =
                this.result_highlight,
                d = b.id;
            this.result_clear_highlight();
            if (this.is_multiple) this.result_deactivate(b);
            else {
                var e = this.search_results.getElement(".result-selected");
                e && e.removeClass("result-selected");
                this.result_single_selected = b
            }
            b.addClass("result-selected");
            b = this.results_data[d.substr(d.lastIndexOf("_") + 1)];
            b.selected = !0;
            this.form_field.options[b.options_index].selected = !0;
            this.is_multiple ? this.choice_build(b) : (this.selected_item.getElement("span").set("text", b.text), this.options.allow_single_deselect &&
                this.single_deselect_control_build());
            (!this.is_multiple || !a.control) && this.results_hide();
            this.search_field.set("value", "");
            this.form_field.fireEvent("change");
            this.search_field_scale()
        }
    },
    result_activate: function(a) {
        a.addClass("active-result")
    },
    result_deactivate: function(a) {
        a.removeClass("active-result")
    },
    result_deselect: function(a) {
        var b = this.results_data[a];
        b.selected = !1;
        this.form_field.options[b.options_index].selected = !1;
        document.id(this.container_id + "_o_" + a).removeClass("result-selected").addClass("active-result").setStyle("display",
            "block");
        this.result_clear_highlight();
        this.winnow_results();
        this.form_field.fireEvent("change");
        this.search_field_scale()
    },
    single_deselect_control_build: function() {
        if (this.options.allow_single_deselect && 1 > this.selected_item.getElements("abbr").length) return this.selected_item.getElement("span").grab(new Element("abbr", {
            "class": "search-choice-close"
        }), "before")
    },
    results_search: function() {
        this.results_showing ? this.winnow_results() : this.results_show()
    },
    winnow_results: function() {
        this.no_results_clear();
        var a = 0,
            b = this.search_field.get("value") === this.default_text ? "" : (new Element("div", {
                text: this.search_field.get("value").trim()
            })).get("html"),
            d = RegExp(b.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), "i"),
            e = RegExp(b.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), "i");

        this.results_data.each(function(c) {
            if (!c.disabled && !c.empty)
                if (c.group) document.id(c.dom_id).setStyle("display", "none");
                else if (!this.is_multiple || !c.selected) {
                var h = !1,
                    f = c.dom_id,
                    i = $(f);


                if (d.test(c.text)) h = !0, a += 1;
                else if (0 <= c.html.indexOf(" ") ||
                    0 === c.html.indexOf("[")) {
                    var g = c.html.replace(/\[|\]/g, "").split(" ");
                    g.length && g.each(function(b) {
                        d.test(b) && (h = !0, a += 1)
                    })
                }
                h ? (b.length ? (g = c.html.search(e), f = c.html) : f = c.html, i.set("html", f), this.result_activate(i), null != c.group_array_index && document.id(this.results_data[c.group_array_index].dom_id).setStyle("display", "list-item")) : (this.result_highlight && f === this.result_highlight.id && this.result_clear_highlight(),
                    this.result_deactivate(i))
            }
        }, this);
        1 > a && b.length ? this.no_results(b) : this.winnow_results_set_highlight()
    },
    winnow_results_clear: function() {
        this.search_field.set("value", "");
        this.search_results.getElements("li").each(function(a) {
            a.hasClass("group-result") ? a.setStyle("display", "block") : (!this.is_multiple || !a.hasClass("result-selected")) && this.result_activate(a)
        }, this)
    },
    winnow_results_set_highlight: function() {
        if (!this.result_highlight) {
            var a = !this.is_multiple ? this.search_results.getElements(".result-selected") : [],
                a = a.length ? a[0] : this.search_results.getElement(".active-result");
            null != a && this.result_do_highlight(a)
        }
    },
    no_results: function(a) {
        var b = (new Element("li", {
            "class": "no-results"
        })).set("html", Locale.get("Chosen.noResults") + ' "<span></span>"');
        b.getElement("span").set("html", a);
        this.search_results.grab(b)
    },
    no_results_clear: function() {
        this.search_results.getElements(".no-results").destroy()
    },
    keydown_arrow: function() {
        if (this.result_highlight) {
            if (this.results_showing) {
                var a = this.result_highlight.getNext("li.active-result");
                a && this.result_do_highlight(a)
            }
        } else(a = this.search_results.getElement("li.active-result")) && this.result_do_highlight(a);
        this.results_showing || this.results_show()
    },
    keyup_arrow: function() {
        if (!this.results_showing && !this.is_multiple) this.results_show();
        else if (this.result_highlight) {
            var a = this.result_highlight.getPrevious("li.active-result");
            a ? this.result_do_highlight(a) : (0 < this.choices && this.results_hide(), this.result_clear_highlight())
        }
    },
    keydown_backstroke: function() {
        this.pending_backstroke ? (this.choice_destroy(this.pending_backstroke.getElement("a")),
            this.clear_backstroke()) : (this.pending_backstroke = this.search_choices.getLast("li.search-choice"), this.pending_backstroke.addClass("search-choice-focus"))
    },
    clear_backstroke: function() {
        this.pending_backstroke && this.pending_backstroke.removeClass("search-choice-focus");
        this.pending_backstroke = null
    },
    keyup_checker: function(a) {
        this.search_field_scale();
        switch (a.key) {
            case "backspace":
                this.is_multiple && 1 > this.backstroke_length && 0 < this.choices ? this.keydown_backstroke() : this.pending_backstroke || (this.result_clear_highlight(),
                    this.results_search());
                break;
            case "enter":
                a.preventDefault();
                this.results_showing && this.result_select(a);
                break;
            case "esc":
                this.results_showing && this.results_hide();
                break;
            case "tab":
            case "up":
            case "down":
            case "shift":
            case "ctrl":
                break;
            default:
                this.results_search()
        }
    },
    keydown_checker: function(a) {
        this.search_field_scale();
        "backspace" !== a.key && this.pending_backstroke && this.clear_backstroke();
        switch (a.key) {
            case "backspace":
                this.backstroke_length = this.search_field.value.length;
                break;
            case "tab":
                this.results_showing &&
                    !this.is_multiple && this.result_select(a);
                this.mouse_on_container = !1;
                break;
            case "enter":
                a.preventDefault();
                break;
            case "up":
                a.preventDefault();
                this.keyup_arrow();
                break;
            case "down":
                this.keydown_arrow()
        }
    },
    search_field_scale: function() {
        if (this.is_multiple) {
            var a = 0,
                a = {
                    position: "absolute",
                    left: "-1000px",
                    top: "-1000px",
                    display: "none"
                },
                b = this.search_field.getStyles("font-size", "font-style", "font-weight", "font-family", "line-height", "text-transform", "letter-spacing");
            Object.merge(a, b);
            b = new Element("div", {
                styles: a
            });
            b.set("text", this.search_field.get("value"));
            $(document.body).grab(b);
            a = b.getDimensions().width + 25;
            b.destroy();
            a > this.f_width - 10 && (a = this.f_width - 10);

            if (!this.is_multiple) {
                this.search_field.setStyle("width", a);
                this.dropdown.setStyle("top", this.container.getCoordinates().height)
            }
        }
    }
});
Element.implement({
    get_side_border_padding: function() {
        var a = this.getStyles("padding-left", "padding-right", "border-left-width", "border-right-width"),
            a = Object.filter(a, function(a) {
                return "string" == typeof a
            }),
            a = Object.map(a, function(a) {
                return a.toInt()
            }),
            a = Object.values(a),
            b = 0,
            d = a.length;
        if (d)
            for (; d--;) b += a[d];
        return b
    },
    select_to_array: function() {
        var a = new SelectParser;
        this.getChildren().each(function(b) {
            a.add_node(b)
        });
        return a.parsed
    }
});
var SelectParser = new Class({
    options_index: 0,
    parsed: [],
    add_node: function(a) {
        "OPTGROUP" === a.nodeName.toUpperCase() ? this.add_group(a) : this.add_option(a)
    },
    add_group: function(a) {
        var b = this.parsed.length;
        this.parsed.push({
            array_index: b,
            group: !0,
            label: a.label,
            children: 0,
            disabled: a.disabled
        });
        a.getChildren().each(function(d) {
            this.add_option(d, b, a.disabled)
        }, this)
    },
    add_option: function(a, b, d) {
        "OPTION" === a.nodeName.toUpperCase() && ("" !== a.text ? (null != b && (this.parsed[b].children += 1), this.parsed.push({
            array_index: this.parsed.length,
            options_index: this.options_index,
            value: a.get("value"),
            text: a.get("text").trim(),
            html: a.get("html"),
            selected: a.selected,
            disabled: !0 === d ? d : a.disabled,
            group_array_index: b,
            classes: a.className,
            style: a.style.cssText
        })) : this.parsed.push({
            array_index: this.parsed.length,
            options_index: this.options_index,
            empty: !0
        }), this.options_index += 1)
    }
});
